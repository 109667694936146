<template>
<main>
    <h1>Not Found!</h1>
    <p>
      <a href="/">Go home?</a>
    </p>
    </main>
</template>

<script>

  export default {
    name: 'NotFound'
  }

</script>

<style scoped>
main {
margin: 125px auto;
  max-width: 900px;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
}

h1 {
  font-size: 5.4rem;
  word-break: break-word;
}


</style>
